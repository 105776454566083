<template>
  <div id="pptPersonal">
    <img src="../../img/板块图标 - 副本_slices/资源 2-8.png" alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
#pptPersonal {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>